
import {Settings as LayoutSettings} from '@ant-design/pro-layout';

export default {
	"navTheme": "realDark", // 风格 realDark
	"primaryColor": '#020C18',
	"layout": "mix", // 'side' | 'top' | 'mix';
	"contentWidth": "Fixed", // Fixed Fluid
	"fixedHeader": true,
	"fixSiderbar": true,
	// "collapsedButtonRender": false,
	"menu": {
		"locale": true,
		"loading": false
	},
	"title": "星途车联智能监管平台",
	"pwa": false,
	"iconfontUrl": "",
	"splitMenus": false
} as LayoutSettings & {
	pwa: boolean;
};
