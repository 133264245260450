import {asyncMemoized, cloneAllItems} from "@gaopeng123/utils";

enum Global {
    user = 'xingtu20-user',
    oem = 'xingtu20-oem' // 定制化信息
}
// 暂时先隐藏起来
export const defaultRouter = '/screen'; // pages screen
/**
 * 获取token信息
 * @returns {any}
 */
export const getToken = () => {
    const userInfo: any = getCurrentUserFromStorage();
    return userInfo.token || null;
};
export const getTanant = () => {
    const userInfo: any = getCurrentUserFromStorage();
    return userInfo.tenant || null;
};

/**
 * 获取用户保存信息
 * @returns {any}
 */
export const getCurrentUserFromStorage = () => {
    return JSON.parse(localStorage.getItem(Global.user) || '{}');
};

export const getUsername = () => {
    const userInfo: any = getCurrentUserFromStorage();
    return userInfo.name;
};

/**
 * 获取第一个路由
 * @param menuInfo
 */
export const getFirstPath = (menuInfo: any) => {
    const child = getIsibleMenus(menuInfo);
    return child ? getPathByRecursion(child) : '';
};

/**
 * 兼容处理
 * @param child
 */
const getChildren = (child: any) => {
    return child.children || child.routers;
}
const getPathByRecursion: any = (child: any) => {
    if (child[0] && getChildren(child[0])?.length) {
        return getPathByRecursion(getChildren(child[0]));
    }
    return child[0] ? child[0].path : '';
};
/**
 * 获取可视菜单
 * @param menuInfo
 */
export const getIsibleMenus = (menus: any = []) => {
    return menus || [];
};

/**
 * 设置用户信息
 * @param value
 */
export const setCurrentUserToStorage = (value: any) => {
    localStorage.setItem(Global.user, JSON.stringify(value));
};
/**
 * 获取本地json数据
 * @param path
 */
export const loadLocalJson = asyncMemoized(async (path: string) => {
    const res = await fetch(path);
    const json = await res.clone().json();
    return cloneAllItems(json);
});

export const defaultOemConfig = {
    loginLogo: '/ub.png', // 登录页logo
    loginName: '星途车联智能监管平台', // 登录页title
    loginDesc: '项目描述信息', // 登录页描述
    menusLogo: `ub.png`, // 菜单上logo
    menusName: `星途车联智能监管平台`, // 菜单上logo
    copyright: `2021 某某出品并提供技术支持`,
    links: [{
        key: 'cy-tech',
        title: '博客地址',
        href: 'https://blog.csdn.net/ligaoming_123',
        blankTarget: true,
    }, {
        key: 'pgli',
        title: 'git地址',
        href: 'https://github.com/ligaopeng123/react-customizescreen',
        blankTarget: true,
    }]
};
/**
 * 获取用户保存信息
 * @returns {any}
 */
export const getCurrentOEMFromStorage = () => {
    return JSON.parse(localStorage.getItem(Global.oem) || JSON.stringify(defaultOemConfig));
};

export default Global;
